.height-64px {
  height: 64px;
}

.max-width-767px {
  max-width: 767px;
}
.remaining-header-width {
  width: calc(100% - 75px);
}

.border-color-secondary-lite {
  border: 1px solid #f2f2f2;
}

.remaining-height-for-country-code {
  height: calc(100% - 80px);
}

.parent-height-sub-64px {
  height: calc(100% - 64px);
}

.parent-height-sub-86px {
  height: calc(100% - 86px);
}

.border-bottom-1px {
  border-bottom: solid 1px;
}

.qr-loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #0249d6;
  background: -moz-linear-gradient(left, #0249d6 10%, rgba(0, 200, 128, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #0249d6 10%,
    rgba(0, 200, 128, 0) 42%
  );
  background: -o-linear-gradient(left, #0249d6 10%, rgba(0, 200, 128, 0) 42%);
  background: -ms-linear-gradient(left, #0249d6 10%, rgba(0, 200, 128, 0) 42%);
  background: linear-gradient(to right, #0249d6 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.qr-loader:before {
  width: 50%;
  height: 50%;
  background: #0249d6;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.qr-loader:after {
  background: #ffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
